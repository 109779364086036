export class Constant {
  static readonly userData = 'user_data';
  static readonly userData1 = 'user_data1';
  static readonly appText = 'app_text';
  static readonly products = 'products';
  static readonly invoiceData = 'invoice_data';
  static readonly userId = 'user_id';
  static readonly clientData = 'client_data';
  static readonly lid = 'lid';
  static readonly token = 'token';
  static readonly darkMode = 'dark_mode';
  static readonly setting = 'setting';
  static readonly langData = 'lang_data';
  static readonly logo = 'logo';
  static readonly url = 'url';
  static readonly year = 'year';
  static readonly invoiceNo = 'invoice_no';
  static readonly pageData = 'page_data';
  static readonly planData = 'plan_data';
  static readonly defaultColor = 'default_color';
  static readonly authorization = 'Authorization';
  static readonly providerId = 'provider_id';
  static readonly commerceId = 'commerce_id';
  static readonly rechargeProvider = 'recharge_provider';
  static readonly packageProvider = 'package_provider';
  static readonly distributorId = 'distributorId';
  static readonly companyId = 'company_id';
  static readonly entityId = 'entity_id';
  //Bnpl
  static readonly bnpl = 'bnpl';
  static readonly balance = 'balance';
  static readonly detailBnpl = 'detailBnpl';
  static readonly codeBnpl = 'codeBnpl';
  static readonly typeFuaPN = 'PN';
  static readonly typeFuaCD = 'CD';
  static readonly typeFuaQR = 'QR';

  static readonly expiredCode = 'BP20750';
  static readonly expiredCodeMessage = 'El código de autorización ya esta vencido. Intentaló de nuevo.';
  static readonly noQuota = 'BP20792';
  static readonly noQuotaMessage = 'No tienes saldo en tu cuenta.';
  static readonly validateQuotaMessage = 'Tu monto disponible no es suficiente para pagar esta compra.';
  static readonly updateQuota = 'BP20790';
  static readonly updateQuotaMessage = 'Realizaste una compra recientemente y estamos actualizando el saldo de tu monto disponible.';
  static readonly trxNotExist = 'BP20751';
  static readonly trxNotExistMessage = 'Código de la transacción incorrecto. Intenta de nuevo.';
  static readonly commerceNotFound = 'BP20793';
  static readonly commerceNotFoundMessage = 'Comercio no encontrado en el sistema. Verifica información e intenta de nuevo.';
  static readonly sessionExpired = 'BP20753';
  static readonly sessionExpiredMessage = '<b>No continuamos con esta transaccion.</b><br> Comunicate con <b>nuestra línea 018000 931987.</b><br> Dales el <b>código de alerta 923.</b> Te ayudarán en un instante.';
  static readonly typeTyC = 'RP';
  static readonly systemUser = 10001;

}
